<template>
  <div>
    <div
      class="d-flex flex-wrap flex-row align-items-start justify-content-between mb-75"
      style="gap: .5rem"
    >
      <div
        class="d-flex align-items-center flex-wrap"
        style="gap: .5rem"
      >
        <!--        <b-button-->
        <!--          variant="primary"-->
        <!--          size="sm"-->
        <!--          :to="{ name: 'settings-panel' }"-->
        <!--          class="text-nowrap mt-25"-->
        <!--        >-->
        <!--          <feather-icon icon="PlusIcon" />-->

        <!--          {{ $t('AddProcess') }}-->
        <!--        </b-button>-->
        <b-button
          v-if="checkRequiredPermissions([$roles.CONTACT_EDIT_DETAILS])"
          variant="primary"
          class="mr-25 text-nowrap"
          size="sm"
          @click="showMassUpdateModal = true"
        >
          <feather-icon icon="DatabaseIcon" />
          {{ $t('UpdateContacts') }}
        </b-button>

        <b-button
          v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_EDIT_ALL, $roles.CONTACT_THREAD_EDIT_ASSIGNED, $roles.CONTACT_THREAD_EDIT_SELF_ASSIGNED])"
          variant="primary"
          size="sm"
          class="mr-25 text-nowrap"
          :disabled="!$store.state.funnelMain.selectedUsers.length"
          @click="showChangeStatusModal = true"
        >
          <feather-icon icon="GridIcon" />
          {{ $t('funnel.ChangeStatus') }}
          <span v-if="$store.state.funnelMain.selectedUsers.length">
            ({{ $store.state.funnelMain.selectedUsers.length }})
          </span>
        </b-button>

        <b-button
          v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_DELETE])"
          variant="primary"
          size="sm"
          class="mr-25 text-nowrap"
          :disabled="!$store.state.funnelMain.selectedUsers.length"
          @click="onDelete"
        >
          <feather-icon icon="TrashIcon" />
          {{ $t('Delete') }}
          <span v-if="$store.state.funnelMain.selectedUsers.length">
            ({{ $store.state.funnelMain.selectedUsers.length }})
          </span>
        </b-button>

        <b-button
          v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_ADD])"
          variant="primary"
          class="mr-25 text-nowrap"
          size="sm"
          @click="showThreadImport = true"
        >
          <feather-icon icon="UploadCloudIcon" />
          {{ $t('Import') }}
        </b-button>

        <ExportButton v-if="checkRequiredHostnameFragments([])"
                      data-type="CONTACT_THREAD"
                      :filters-params="getFilters()"
        />
        <ExportModal v-else
                     :export-loading="exportLoading"
                     @export="getRecordsToExport"
        />
        <!--        <b-button-->
        <!--          variant="primary"-->
        <!--          size="sm"-->
        <!--          :disabled="exportLoading"-->
        <!--          @click="getRecordsToExport"-->
        <!--        >-->
        <!--          <span v-if="!exportLoading"><feather-icon icon="DownloadIcon" />Export</span>-->
        <!--          <span v-else>-->
        <!--            <b-spinner-->
        <!--              small-->
        <!--              variant="light"-->
        <!--            />-->
        <!--          </span>-->
        <!--        </b-button>-->

        <sw-select
          classes="mb-0"
          style="min-width: 15rem"
        >
          <v-select
            v-model="selectedFunnel"
            :reduce="funnel => funnel.id"
            :options="funnelList"
            label="name"
            class="select-size-sm"
            style="min-width: 15rem"
            :clearable="false"
            @input="id => changeFunnel(id)"
          >
            <template #selected-option="{ name }">
              {{ name | truncate(20) }}
            </template>
            <template #option="{ name }">
              {{ name | truncate(20) }}
            </template>
          </v-select>
        </sw-select>

        <div
          class="limiter"
          :class="{ 'd-none': selectedDisplay === 'table' }"
        >
          <sw-select
            classes="mb-0"
          >
            <v-select
              v-model="filters.limit"
              class="select-size-sm"
              :options="[50, 100, 250, 500]"
              :clearable="false"
            />
          </sw-select>
        </div>
      </div>

      <div
        class="d-flex flex-wrap align-items-center"
        style="gap: .5rem"
      >
        <b-button-group>
          <b-button
            v-b-tooltip.hover
            size="sm"
            :title="$t('funnel.FunnelView')"
            variant="outline-primary"
            :class="{ 'active-funnel-view': selectedDisplay === 'funnel' }"
            @click="changeFunnelView('funnel')"
          >
            <feather-icon
              icon="GridIcon"
              style="transform: rotate(180deg)"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover
            size="sm"
            :title="$t('funnel.FunnelViewCompressed')"
            variant="outline-primary"
            :class="{ 'active-funnel-view': selectedDisplay === 'funnel-compressed' }"
            @click="changeFunnelView('funnel-compressed')"
          >
            <feather-icon
              icon="BarChart2Icon"
              style="transform: rotate(180deg)"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover
            size="sm"
            :title="$t('funnel.TableView')"
            variant="outline-primary"
            :class="{ 'active-funnel-view': selectedDisplay === 'table' }"
            @click="changeFunnelView('table')"
          >
            <feather-icon
              icon="ListIcon"
            />
          </b-button>
        </b-button-group>
      </div>
    </div>

    <!--    Section: Filters    -->
    <Filters :is-funnel="selectedDisplay === 'funnel' || selectedDisplay === 'funnel-compressed'" />

    <!-- eslint-disable -->
    <Funnel
      v-if="selectedDisplay === 'funnel' || selectedDisplay === 'funnel-compressed'"
      :target="target"
      ref="funnel"
      @closeModal="showMassUpdateModal = false; showThreadImport = false"
      :show-mass-update-modal="showMassUpdateModal"
      :show-thread-import="showThreadImport"
      :is-compressed="selectedDisplay === 'funnel-compressed'"
      @changeTarget="onChangeTarget"
    />

    <sw-table
      v-else
      ref="table"
      table-id="4e747ff459b2420190c6ed0f320f26f3"
      @closeModal="showMassUpdateModal = false; showThreadImport = false"
      :show-mass-update-modal="showMassUpdateModal"
      :show-thread-import="showThreadImport"
      :target="target"
      @changeTarget="onChangeTarget"
    />

      <b-modal v-model="showChangeStatusModal"
               hide-footer
               no-close-on-backdrop
               @hide="changeStatus = ''; showChangeStatusModal = false"
               :title="$t('funnel.ChangeStatus')"
      >

          <sw-select :name="$t('Status')">
              <v-select
                      v-model="changeStatus"
                      label="name"
                      :options="contactThreadFunnelTypesPoints.filter(e => e.typeId === selectedFunnel)"
              />
          </sw-select>

          <b-button
                  variant="primary"
                  size="sm"
                  :disabled="!changeStatus"
                  @click="saveChangeStatus"
          >
              <span>{{ $t('Save') }}</span>
          </b-button>
      </b-modal>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="primary"
    />
  </div>
</template>

<script>
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { loadFromLocalStorage, saveToLocalStorage } from '@/helpers/localStorage'
import { GET_FUNNELS } from '@/@constants/mutations'
import SwTable from '@/views/components/funnel/Table.vue'
import Filters from '@/views/filters/FunnelFilters.vue'
import Funnel from '@/views/components/funnel/Funnel.vue'
import XLSX from 'xlsx'
import { formatDate } from '@core/utils/filter'
import axiosIns from '@/libs/axios'
import ExportModal from '@core/components/@sw/ExportModal.vue'
import ExportButton from '@/views/migrations/ExportButton.vue'

export default {
  name: 'SalesFunnel',
  components: {
    ExportButton,
    ExportModal,
    SwTable,
    Funnel,
    Filters,
    BButtonGroup,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    propsUser: {
      type: Object,
      required: false,
      default: null,
    },
    showDelayed: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      filters: 'funnelMain/getFilters',
      currentUser: 'auth/getCurrentUser',
    }),
  },
  data: () => ({
    isLoading: false,

    showThreadImport: false,
    showMassUpdateModal: false,

    funnelList: [],
    contactThreadFunnelTypesPoints: [],

    selectedDisplay: 'funnel',
    target: null,
    showChangeStatusModal: false,
    selectedFunnel: '',
    exportLoading: false,
    changeStatus: '',

    scrollSpeed: 10,
    isScrolling: false,
    direction: null,
    isMounted: false,
    intervalId: null,
    done: false,
  }),
  watch: {
    filters: {
      deep: true,
      // eslint-disable-next-line no-unused-vars
      async handler(nw, ow) {
        if (this.isMounted && !this.done) {
          this.done = true

          setTimeout(() => {
            this.done = false
          }, 500)

          console.log('save', nw)
          const newConfig = { ...(this.currentUser.configTables || {}), 'sales-funnel-filters': JSON.stringify({ ...nw, assignedUsers: nw.assignedUsers.map(e => ({ firstName: e.firstName, lastName: e.lastName, id: e.id })) }) }
          this.currentUser.configTables = newConfig
          await axiosIns.patch(`1/users/${this.currentUser.id}/profile`, { id: this.currentUser.id, configTables: newConfig })
        }
      },
    },
  },
  created() {
    window.addEventListener('scroll', () => {
      this.$root.$emit('bv::hide::popover')
    })
  },
  async mounted() {
    this.isMounted = false
    await this.loadThreadStatuses()
    await this.getFunnels()

    if (loadFromLocalStorage('funnel_view')) {
      this.$set(this, 'selectedDisplay', loadFromLocalStorage('funnel_view'))
    }

    if (this.currentUser.configTables && this.currentUser.configTables['sales-funnel-filters']) {
      const filters = JSON.parse(this.currentUser.configTables['sales-funnel-filters'])
      Object.assign(this.filters, filters)
    } else {
      this.filters.assignedUsers = [this.currentUser]
      if (loadFromLocalStorage('funnel_assigned_filter')) {
        this.$store.commit('funnelMain/SET_FILTER', { field: 'assignedUsers', value: loadFromLocalStorage('funnel_assigned_filter') })
      }

      if (this.propsUser) {
        this.filters.assignedUsers = [this.propsUser]
        this.$store.commit('funnelMain/SET_FILTER', { field: 'assignedUsers', value: [this.propsUser] })
      }

      if (this.showDelayed) {
        this.filters.delayedContactThreadStatuses = true
        this.$store.commit('funnelMain/SET_FILTER', { field: 'delayedContactThreadStatuses', value: true })
      }
    }

    setTimeout(() => {
      if (this.funnelList.length) {
        if (this.$route.params.id) {
          this.$set(this, 'selectedFunnel', this.$route.params.id)
        } else {
          this.selectedFunnel = this.funnelList[0].id

          this.changeFunnel(this.funnelList[0].id)
        }
        setTimeout(() => {
          this.isMounted = true
        }, 1000)
      }
    }, 100)
  },
  methods: {
    async saveChangeStatus() {
      const { selectedUsers = [] } = this.$store.state.funnelMain

      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      // eslint-disable-next-line no-useless-return
      if (!confirmed) return

      try {
        const payload = selectedUsers.map(item => ({ id: item.openedId, contactThreadFunnelTypePoint: this.getObjectId(this.changeStatus) }))
        await axiosIns.patch('1/contacts/threads/funnelProcesses', payload)

        if (this.$refs.funnel) {
          // eslint-disable-next-line no-unused-expressions
          this.$refs.funnel?.getFunnelStructure()
        }
        if (this.$refs.table) {
          // eslint-disable-next-line no-unused-expressions
          this.$refs.table?.getFunnelStructure()
        }

        this.showChangeStatusModal = false
        this.changeStatus = ''
        this.$store.state.funnelMain.selectedUsers = []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async loadThreadStatuses() {
      try {
        const resp = await axiosIns.get('1/settings/contactThreadFunnelTypes', { params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })

        if (resp?.data?.data?.items) {
          this.contactThreadFunnelTypesPoints = (resp.data.data.items || []).flatMap(type => type.contactThreadFunnelTypePoints.map(point => ({ ...point, typeId: type.id, name: `${type.name} - ${point.name}` })))
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    onChangeTarget(target) {
      this.target = target
    },
    changeFunnelView(view) {
      this.$store.state.funnelMain.selectedUsers = []
      saveToLocalStorage('funnel_view', view)
      this.selectedDisplay = view
    },
    changeFunnel(id) {
      if (id) this.$router.replace(`/sales-funnel/${id}`)
    },

    getFunnels() {
      this.isLoading = true

      return this.$store.dispatch(`funnelMain/${GET_FUNNELS}`)
        .then(res => {
          this.funnelList = res
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    getFilters() {

    },

    /* eslint-disable */
    async getRecordsToExport(filters) {
      this.exportLoading = true
      try {
        const { id } = this.$route.params

        const funnelPoints = await this.$store.dispatch('funnelMain/GET_FUNNEL_POINTS', { funnelId: id })
        const points = funnelPoints.map(point => point.id)

        const { total } = await this.loadExportItems(1, 5, points)
        const chunksToLoad = Math.ceil(total / 75)
        const requests = new Array(chunksToLoad).fill(null)
        // const response = await Promise.all(requests.map((_, index) => this.loadExportItems(index + 1, 75, points)))
        const response = []

        await (new Promise((resolve, reject) => {
            requests.forEach((_, index) => {
                setTimeout(async () => {
                    response.push(await this.loadExportItems(index + 1, 75, points))

                    if (index === requests.length -1) resolve();
                }, index * 1000)
            })
        }))

        const items = []
        response.forEach(res => {
          items.push(...res?.items.map(exportedItem => ({
            ...exportedItem,
          })))
        })

        const chunksToLoadAddon = Math.ceil(items.length / 25)
        const requestsAddon = items.splitIntoChunks(chunksToLoadAddon)

        await (new Promise((resolve, reject) => {
            items.forEach((_, index) => {
                setTimeout(async () => {
                    const params = {
                        limit: 0,
                        fields_load: 'BASE_ID',
                        eq_contactThread: _.id
                    }
                    if (filters.date?.length) {
                        params.gte_createdAt = filters.date[0]
                        if (filters.date[1]) {
                            params.lte_createdAt = filters.date[1]
                        }
                    }
                    const taskResponse = await axiosIns.get('1/contacts/threads/tasks', {params})
                    const noteResponse = await axiosIns.get('1/contacts/threads/notes', {params})

                    items[index] = {
                        ..._,
                        notesCount: noteResponse.data.data.totalItemCount,
                        tasksCount: taskResponse.data.data.totalItemCount,
                    }

                    if (index === items.length -1) {
                        resolve();
                    }
                }, index * 300)
            })
        }))

        this.$nextTick(() => {
          this.exportRecords(items)
        })
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    /* eslint-disable */
      async exportRecords(recordsList) {
          const wb = XLSX.utils.book_new()

          const order = [
              { key: 'id', label: 'ID' },
              { key: 'contact', label: 'Contact' },
              { key: 'phone', label: 'Phone' },
              { key: 'email', label: 'E-mail' },
              { key: 'contactStatus', label: 'AutomationSection.ContactStatus' },
              { key: 'threadStatus', label: 'AutomationSection.ContactThreadFunnelTypePoint' },
              { key: 'name', label: 'Thread' },
              { key: 'link', label: 'Link' },
              { key: 'status', label: 'Status' },
              { key: 'company', label: 'Company' },
              { key: 'statusClosedAt', label: 'ClosingDate' },
              { key: 'contactThreadStatusReason', label: 'Reason' },
              { key: 'statusNote', label: 'Note' },
              { key: 'predictedValue', label: 'CustomerValue' },
              { key: 'assignedUsers', label: 'AssignedUser' },
              { key: 'notesCount', label: 'Notes' },
              { key: 'tasksCount', label: 'Tasks' },
              { key: 'createdAt', label: 'ContactCreatedAt' },
          ]

          const aoaToExport = [
              ...recordsList.sort((a, b) => (b.status > a.status) ? 1 : -1).map(t => order.map(({ key }) => {
                  if (key === 'contact') {
                      if (t[key]) return `${this.getUserName(t[key])}`
                      else return `-`
                  }

                  else if (key === 'statusClosedContactThreadFunnelTypePoint' && t[key]) {
                      if (t.contactThreadFunnelType?.name) return `${t.contactThreadFunnelType?.name} - ${t[key]?.name || ''}`
                      return t[key]?.name || '-'
                  }

                  else if (key === 'contactThreadStatusReason') {
                      return t[key]?.name || '-'
                  }

                  else if (key === 'phone') {
                      return t?.contact?.phone || '-'
                  }

                  else if (key === 'email') {
                      return t?.contact?.email || '-'
                  }

                  else if (key === 'contactStatus') {
                      return t?.contact?.status || '-'
                  }

                  else if (key === 'createdAt') {
                      return t?.contact?.createdAt ? this.formatDate(t[key].contact.createdAt.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, this.$i18n.locale) : '-'
                  }

                  else if (key === 'company' && t?.contact?.organization) {
                      return t?.contact?.organization?.name || '-'
                  }

                  else if (key === 'files') {
                      return t[key]?.length || 0
                  }

                  else if (key === 'status') {
                      let status = this.$i18n.t('WasOpened')
                      if (t[key] === 'CLOSED_POSITIVE') status = this.$i18n.t('funnel.status.COMPLETED')
                      if (t[key] === 'CLOSED_NEGATIVE') status = this.$i18n.t('funnel.status.REJECTED')
                      if (t[key] === 'PAUSED') status = this.$i18n.t('Paused')
                      return status
                  }

                  else if (key === 'link') {
                      return t.id && t?.contact?.id ? `${window.location.origin}/contact/${t.contact.id}/thread/${t.id}/timeline` : '-'
                  }

                  else if (key === 'assignedUsers') {
                      return t[key] ? t[key]?.map(user => this.getUserName(user))?.join(', ') : '-'
                  }

                  else if (typeof t[key] === 'boolean') return t[key] ? 1 : 0
                  else if (key === 'updatedAt' && t[key]?.date) {
                      return t.status !== 'O' ? this.formatDate(t[key].date, {
                          month: 'numeric',
                          day: 'numeric',
                          year: 'numeric'
                      }, this.$i18n.locale) : '-'
                  }
                  else if (t[key]?.date) return this.formatDate(t[key].date, { month: 'numeric', day: 'numeric', year: 'numeric' }, this.$i18n.locale)

                  return t[key] ?? ''
              }).flat(Infinity)),
          ]

          const headers = order.map(({ label }) => this.$i18n.t(label))
          aoaToExport.unshift(headers)

          const ws = XLSX.utils.aoa_to_sheet(aoaToExport);
          XLSX.utils.book_append_sheet(wb, ws, `${this.$i18n.t('FunnelsAndProcesses').replace(' ', '_')}`);
          XLSX.writeFile(wb, `${this.$i18n.t('FunnelsAndProcesses').replace(' ', '_')}.xlsx`);

          this.exportLoading = false
      },
      formatDate,
      async loadAddonData(array = []) {
          return new Promise(async resolve => {
              const newArray = []
              for (const a of array) {
                  const params = {
                      limit: 0,
                      fields_load: 'BASE_ID',
                      eq_contactThread: a.id
                  }
                  const taskResponse = await axiosIns.get('1/contacts/threads/tasks', {params})
                  const noteResponse = await axiosIns.get('1/contacts/threads/notes', {params})

                  newArray.push({
                      ...a,
                      notesCount: noteResponse.data.data.totalItemCount,
                      tasksCount: taskResponse.data.data.totalItemCount,
                  })
              }

              resolve(newArray)
          })
      },
      async loadExportItems(page = 1, perPage = 1, points = []) {
          const params = {
              'eqArr_contactThreadFunnelTypePoint-id': JSON.stringify(points),
              fields_load: this.$fields.FUNNEL,
              limit: perPage,
              page,
          }

          if (this.filters) {
              if (this.filters.assignedUsers && this.filters.assignedUsers.length) {
                  params['eqArr_contactThread-assignedUsers-id'] = JSON.stringify(
                      this.filters.assignedUsers.map(
                          user => (typeof user === 'object' ? user.id : user),
                      ),
                  )
              }
              // Thread
              if (this.filters.delayedContactThreadStatuses) {
                  params.lt_contactThreadFunnelTypePointDelayedAt = this.getCurrentDate()
              }

              if (this.filters.contactStructure) {
                  params.structure = true
              }

              if (this.filters && this.filters.contactCloseStatus) {
                  params.eq_status = this.filters.contactCloseStatus.toString()
              }

              if (this.filters.contactsOverdueTasks) {
                  params['lt_contactThread-contactThreadTasks-startAt'] = this.getCurrentDate()
                  params['eq_contactThread-contactThreadTasks-status'] = 'O'
              }

              if (this.filters.delayedContactStatuses) {
                  params['lt_contactThread-contact-statusDelayedAt'] = this.getCurrentDate()
              }

              if (this.filters.withOrganization) {
                  params['isNotNull_contactThread-contact-organization'] = ''
              }

              if (this.filters.tags && this.filters.tags.length) {
                  const tags = this.filters.tags.map(tag => (typeof tag === 'string' ? tag : tag.id))
                  if (this.filters.tagsSum) {
                      params['inD_contactThread-contact-contactTypes-id'] = JSON.stringify(tags)
                  } else {
                      params['eqArr_contactThread-contact-contactTypes-id'] = JSON.stringify(tags)
                  }
              }

              if (this.filters.closeStatuses && this.filters.closeStatuses.length) {
                  params.eqArr_status = JSON.stringify(this.filters.closeStatuses)
              }
          }

          const { data } = await axiosIns.get('1/contacts/threads/funnelProcesses', { params })

          const items = data.data.items.map(i => ({ ...i, ...i.contactThread, notesCount: -10, tasksCount: -10 }))

          return { total: data.data.totalItemCount, items }
      },
      onDelete() {
          const { selectedUsers = [] } = this.$store.state.funnelMain

          this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
              .then(async () => {
                  try {
                      await axiosIns.delete('1/contacts/threads', { data: JSON.stringify(selectedUsers.mapKeys('id')) })

                      if (this.$refs.funnel) {
                          this.$refs.funnel?.getFunnelStructure()
                      }
                      if (this.$refs.table) {
                          this.$refs.table?.getFunnelStructure()
                      }

                      this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
                  } catch (err) {
                      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                  } finally {
                      this.$store.state.funnelMain.selectedUsers = []
                  }
              })
      },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .active-funnel-view {
    background-color: rgba(115, 103, 240, 0.2);
  }
</style>
