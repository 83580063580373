<!--eslint-disable-->

<template>
  <b-card id="table">
    <user-arrears-popover
      :target="target"
      container="table"
      @openModal="openModal"
    />
    <!--  Table Edit Header  -->

    <!-- New Table View -->
    <!--   eslint-disable   -->
      <sw-table
        table-id="c520b6ebf05f41c890f87ad53208043e"
        show-columns
        :fields.async="userFields"
        :pagination="pagination"
        :sorter="sorter"
        show-search
        show-sorter
        @sort-changed="Object.assign(sorter, $event); getFunnelStructure()"
        @change-search-query="search = $event"
        @change-pagination="Object.assign(pagination, $event)"
        @reload-content="getFunnelStructure"
        @set-fields="userFields = $event"
      >
        <template #table>
          <!--      Table      -->
          <b-table
              :items="tableData"
              :fields="fields"
              striped
              responsive
              :busy="loading"
              show-empty
          >
            <!--    Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>
            <template #cell(id)="{ index }">
                #{{ getOrderNo(index, pagination, sorter) }}
            </template>
            <template #head(select)>
              <b-form-checkbox
                  v-model="checkedAll"
                  @change="toggleChecked"
              />
            </template>

            <template #cell(select)="{ item }">
              <b-form-checkbox
                  v-model="$store.state.funnelMain.selectedUsers"
                  :value="item"
              />
            </template>

            <template #cell(arrears)="{ item }">
                <div class="d-flex">
                    <div v-if="item.status">
                        <b-button
                                style="padding: 5px"
                                v-if="item.status !== 'OPEN' && item.status === 'PAUSED'"
                                class="btn-icon rounded-circle mr-25"
                                :variant="'warning'"
                                v-b-tooltip
                                :title="$t('Paused')"
                        >

                            <feather-icon size="17" :icon="'PauseIcon'" />
                        </b-button>
                        <b-button
                                style="padding: 5px"
                                v-else-if="item.status !== 'OPEN' && item.status !== 'PAUSED'"
                                class="btn-icon rounded-circle mr-25"
                                :variant="item.status === 'CLOSED_POSITIVE' ? 'success' : 'danger'"
                                v-b-tooltip
                                :title="item.status === 'CLOSED_POSITIVE' ? $t('funnel.status.COMPLETED') : $t('funnel.status.REJECTED')"
                        >

                            <feather-icon size="17" :icon="item.status === 'CLOSED_POSITIVE' ? 'CheckIcon' : 'XIcon'" />
                        </b-button>
                    </div>
                    <user-arrears
                            :user="item"
                            @changeTarget="changeTarget"
                    />
                </div>
            </template>

            <template #cell(contactThread)="{ item }">
              <span v-if="item" class="text-nowrap">
                <router-link :class="{ 'deleted-text': item.contact.deletedAt }" :to="`/contact/${item.contact.id}/thread/${item.id}/payments`">
                  {{ item.name | truncate(20) }}
                </router-link>
              </span>
              <span v-else>
                <feather-icon icon="MinusIcon" />
              </span>
            </template>

            <template #cell(contact)="{ value }">
              <avatar v-if="value" :user="value" />
              <span v-else>
                <feather-icon icon="MinusIcon" />
              </span>
            </template>

            <template #cell(agreeMarketing)="{ item }">
              <b-form-checkbox
                  variant="primary"
                  :checked="item.contact.agreeMarketing"
                  disabled
              />
            </template>

            <template #cell(assignedUsers)="{ value }">
              <avatar-group :items="value" />
            </template>

            <template #cell(status)="{ item, index }">
              <b-badge
                  v-if="item.contact.status && item.contact.status.name"
                  :id="`status_row_${item.id}_${index}`"
                  class="cursor-pointer"
                  :style="{ backgroundColor: item.contact.status.color + ' !important' }"
              >
                {{ item.contact.status.name }}
              </b-badge>
              <feather-icon v-else icon="MinusIcon" />
              <b-popover
                  v-if="item.contact.status && item.contact.status.name"
                  :target="`status_row_${item.id}_${index}`"
                  placement="left"
                  triggers="hover"
              >
                <template #title>
                    <span>
                      {{ $t('funnel.ChangeStatus') }}
                    </span>
                </template>
                <b-list-group flush>
                  <b-list-group-item
                      v-for="status in contactStatuses"
                      :key="`status__${status.id}`"
                      :disabled="status.id === item.contact.status.id"
                      class="cursor-pointer"
                      @click="onChangeContactStatus(status.id, item.id, item.contact)"
                  >
                    <span v-if="status && status.name">{{ status.name }}</span>
                    <feather-icon
                        v-else
                        icon="MinusIcon"
                    />
                  </b-list-group-item>
                </b-list-group>
              </b-popover>
            </template>

            <template #cell(contactTypes)="{ item, index }">
              <div v-if="Array.isArray(item.contact.contactTypes) && item.contact.contactTypes.length">
                <b-badge
                    v-for="tag in item.contact.contactTypes"
                    :key="`tag_${tag.id}_${index}`"
                    variant="light-secondary"
                    class="mr-25 mb-25"
                >
                  <div class="d-flex">
                    <div
                        class="color-dot mr-25"
                        :style="{ backgroundColor: tag.color || '#fff' }"
                        style="border: 1px solid white"
                    />
                    {{ tag.name }}
                  </div>
                </b-badge>
              </div>
              <feather-icon
                  v-else
                  icon="MinusIcon"
              />
            </template>

            <template #cell(checklistStatus)="{ item, index }">
              <b-badge
                  :id="`status_checklistStatus_row_${item.id}`"
                  class="cursor-pointer"
                  variant="light-primary"
              >
                <template v-if="item.contactThreadChecklistDetails.length">
                  {{ `${checklistProgress(item.contactThreadChecklistDetails).done} / ${checklistProgress(item.contactThreadChecklistDetails).all}` }}
                </template>

                <feather-icon
                    v-else
                    icon="MinusIcon"
                />
              </b-badge>
            </template>

            <template #cell(statusesProgress)="{ item, index }">
              <b-badge
                  :id="`status_checklistStatus_row_${item.id}`"
                  class="cursor-pointer"
                  variant="light-primary"
              >
                <template v-if="item.contactThreadChecklistDetails.length">
                  {{ checklistProgress(item.contactThreadChecklistDetails).percent }}%
                </template>

                <feather-icon
                    v-else
                    icon="MinusIcon"
                />
              </b-badge>
            </template>

            <template #cell(predictedValue)="{ item, index }">
              <div >
                <template v-if="item.predictedValue">
                  {{ item.predictedValue | priceFormat }} {{ item.predictedValueCurrency || 'PLN' }}
                </template>

                <feather-icon
                    v-else
                    icon="MinusIcon"
                />
              </div>
            </template>

            <template #cell(organization)="{ item }">
              <span v-if="item.contact.organization">
                <span v-if="item.contact.organization.name">
                  <b-link v-if="checkRequiredPermissions([$roles.ORGANIZATION_VIEW_ALL]) && item.contact.organization.id"
                          :to="`/organizations/${item.contact.organization.id}`"
                          target="_blank"
                  >
                  {{ item.contact.organization.name }}
                </b-link>
                <span v-else>{{ item.contact.organization.name }}</span>
                </span>
                <feather-icon
                    v-else
                    icon="MinusIcon"
                />
              </span>
              <feather-icon
                  v-else
                  icon="MinusIcon"
              />
            </template>
            <template #cell(vatId)="{ item }">
          <span v-if="item.contact.organization">
            <span v-if="item.contact.organization.vatId">{{ item.contact.organization.vatId }}</span>
            <feather-icon
                v-else
                icon="MinusIcon"
            />
          </span>
              <feather-icon
                  v-else
                  icon="MinusIcon"
              />
            </template>

            <template #cell(address)="{ item }">
              <div class="text-nowrap">
                {{ getAddress(item.contact) }}
              </div>
            </template>
            <template #cell(addressCorrespondence)="{ item }">
              <div class="text-nowrap">
                {{ getAddress(item.contact, 'correspondence') }}
              </div>
            </template>

            <template #cell(contactThreadFunnelTypePoint)="{ item }">
              <b-badge
                  v-if="item.contactThreadFunnelTypePoint && item.contactThreadFunnelTypePoint.name"
                  :id="`status_${item.id}`"
                  class="cursor-pointer"
                  variant="light-primary"
              >
                {{ item.contactThreadFunnelTypePoint.name }}

                  <sw-icon v-if="item.contactThreadFunnelTypePoint.description"
                           v-b-tooltip
                           class="text-primary ml-25"
                           icon="AlertCircleIcon"
                           :title="item.contactThreadFunnelTypePoint.description"
                  />
              </b-badge>
              <feather-icon
                  v-else
                  icon="MinusIcon"
              />
            </template>

            <template #cell(createdBy)="{ value }">
              <avatar :user="value" />
            </template>

            <!--    Other    -->
              <!--    Other    -->
              <template #cell()="row">
                  <table-default-cell
                          :field="row.field"
                          :value="row.value"
                  />
              </template>

              <!--      Empty      -->
              <template #empty>
                  <empty-content />
              </template>

              <!--      Table Busy      -->
              <template #table-busy>
                  <div class="text-center text-danger my-2">
                      <b-spinner
                              class="align-middle"
                              variant="primary"
                      />
                  </div>
              </template>
          </b-table>
        </template>
      </sw-table>

      <mass-update-modal
              :key="'mass-update-modal-table' +massUpdateContacts"
              :contacts="massUpdateContacts"
              :show-modal="showMassUpdateModal"
              @close-modal="closeMassUpdateModal"
      />

      <import-contact-threads-modal
              v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_ADD])"
              :show="showThreadImport"
              @close-modal="() => { $emit('closeModal'); getFunnelStructure() }"
              @reload-content="closeMassUpdateModal"
      />
  </b-card>
</template>

<script>
/* eslint-disable */

import axiosIns from '@/libs/axios'
import UserArrears from '@/views/components/userArrears/UserArrears.vue'
import UserArrearsPopover from '@/views/components/userArrears/UserArrearsPopover.vue'
import { GET_CONTACT_STATUSES, OPEN_MODAL } from '@/@constants/mutations'
import { BPopover, BTable, VBPopover, VBTooltip, } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import * as fields from '@/@constants/fields'
import { formatDate } from '@core/utils/filter'
import MassUpdateModal from '@/pages/contact.old/components/MassUpdateModal.vue'
import ImportContactThreadsModal from "@/views/modals/ImportContactThreadsModal.vue";

export default {
  name: 'Table',
  components: {
    ImportContactThreadsModal,
    MassUpdateModal,
    UserArrears,
    UserArrearsPopover,
    BPopover,
    BTable,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: {
    showMassUpdateModal: {
      default: false,
      required: false,
    },
    showThreadImport: {
      default: false,
      required: false,
    },
  },
  data: () => ({
    tableData: [],
    search: '',
    userFields: [
      { label: 'select', key: 'Select', visible: true },
      {
        key: 'id', label: '#', visible: true, sortBy: 'id',
      },
      { label: 'contactTabs.Information', key: 'arrears', visible: true },
      { label: 'contact.contactThread', key: 'contactThread', visible: true },
      { label: 'Contact', key: 'contact', visible: true },
      { label: 'contact.contactThreadFunnelTypePoint', key: 'contactThreadFunnelTypePoint', visible: true },
      { label: 'CustomerValue', key: 'predictedValue', visible: true },
      { label: 'contact.email', key: 'email', visible: true },
      { label: 'contact.agreeMarketing', key: 'agreeMarketing', visible: true },
      { label: 'contact.assignedUsers', key: 'assignedUsers', visible: true },
      { label: 'contact.status', key: 'status', visible: true },
      { label: 'contact.contactTypes', key: 'contactTypes', visible: true },
      { label: 'contact.checklistStatus', key: 'checklistStatus', visible: true },
      // { label: 'contact.statusesProgress', key: 'statusesProgress', visible: true },
      { label: 'Organization', key: 'organization', visible: true },
      { label: 'contact.vatId', key: 'vatId', visible: true },
      { label: 'Address', key: 'address', visible: true },
      { label: 'AddressForCorrespondence', key: 'addressCorrespondence', visible: true },
      { label: 'contact.phone', key: 'phone', visible: true },
    ],
    // pagination: {
    //   page: 1,
    //   limit: 100,
    //   options: [2, 25, 50, 100, 250],
    // },
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },
    target: null,
    massUpdateContacts: [],
    displayedFields: [],
    selectedRowIndex: null,
    loading: true,
    checkedAll: false,
  }),
  watch: {
    $route() {
      this.getFunnelStructure()
    },
    filters: {
      deep: true,
      handler() {
        this.getFunnelStructure()
      },
    },
  },
  computed: {
    ...mapGetters({
      contactStatuses: 'typeSettings/geContactStatuses',
      filters: 'funnelMain/getFilters',
    }),
    fields() {
      return this.userFields.filter(field => field.visible)
    },
  },
  mounted() {
    this.$store.dispatch(`typeSettings/${GET_CONTACT_STATUSES}`)
    this.getFunnelStructure()
  },
  methods: {
    closeMassUpdateModal() {
      this.$emit('closeModal')
      this.massUpdateContacts = []
      this.getFunnelStructure()
    },
    toggleChecked(value) {
      if (value) this.$store.state.funnelMain.selectedUsers = this.tableData.map(item => item)
      else this.$store.state.funnelMain.selectedUsers = []
    },
    formatDate,
    checklistProgress(checklist) {
      let numberOfChecklist = 0
      let numberOfDoneChecklist = 0

      checklist.forEach(detail => {
        numberOfChecklist += detail.contactThreadChecklistTypePointsCount
        numberOfDoneChecklist += detail.contactThreadChecklistPointDataProgress
      })

      if (numberOfDoneChecklist > numberOfChecklist) console.error(`[SW -> Checklist Item] The number of completed Checklist Items cannot exceed the number of all Checklist Items. Done ${numberOfDoneChecklist} out of ${numberOfChecklist}`)

      return {
        done: numberOfDoneChecklist,
        all: numberOfChecklist,
        percent: Math.round((numberOfDoneChecklist * 100) / numberOfChecklist),
      }
    },
    getCurrentDate() {
      const date = new Date()
      const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
      const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
      const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
      const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
      return `${date.getFullYear()}-${month}-${day} ${hour}:${minutes}:${seconds}`
    },
    getFunnelStructure() {
      this.loading = true
      const { id } = this.$route.params
      // eslint-disable-next-line no-unused-vars
      let funnelItems = []
      this.$nextTick(() => {
        this.$store.dispatch('funnelMain/GET_FUNNEL_POINTS', { funnelId: id })
          .then(funnelPoints => {
            const points = funnelPoints.map(point => point.id)
            this.funnelPointsIds = points
            const params = {
              fields_load: fields.FUNNEL,
              limit: this.pagination.perPage,
              page: this.pagination.currentPage,
              'eqArr_contactThreadFunnelTypePoint-id': JSON.stringify(points),
            }

            if (this.search) {
              params.search = `%${this.search}%`
              params.search_fields = 'contactThread-contact-firstName,contactThread-contact-lastName'
            }

            if (this.sorter) {
              const sortDir = this.sorter?.sortDesc ? 'desc' : 'asc'
              const sortBy = this.sorter?.sortBy || 'id'
              params.orderBy = `${sortBy}.${sortDir}`
            }

            if (this.filters && this.filters.status) {
              params.eq_contactThreadFunnelTypePoint = this.filters.status.toString()
            }

            if (this.filters.createdAt) {
                const splitedDate = this.filters.createdAt.dateRange()
                params[`gte_contactThread-createdAt`] = `${splitedDate[0]} 00:00:00`
                if (splitedDate[1]) {
                    params[`lte_contactThread-createdAt`] = `${splitedDate[1]} 23:59:59`
                }
            }

            if (this.filters.agreeMarketing) {
                params['eq_contactThread-contact-agreeMarketing'] = 1
            }

            if (this.filters.delayedContactStatuses) {
              params['lt_contactThread-contact-statusDelayedAt'] = this.getCurrentDate()
            }

            if (this.filters && this.filters.contactCloseStatus) {
              params.eq_status = this.filters.contactCloseStatus.toString()
            }
            // else {
            //   console.log(points)
            //   params.eqArr_contactThreadTaskType = JSON.stringify(points)
            // }

            if (this.filters) {
              if (this.filters.assignedUsers && this.filters.assignedUsers.length) {
                params['eqArr_contactThread-assignedUsers-id'] = JSON.stringify(
                  this.filters.assignedUsers.map(
                    user => (typeof user === 'object' ? user.id : user),
                  ),
                )
              }
              // Thread
              if (this.filters.delayedContactThreadStatuses) {
                params.lt_contactThreadFunnelTypePointDelayedAt = this.getCurrentDate()
              }

              if (this.filters.contactStructure) {
                params.structure = true
              }

              if (this.filters.contactsOverdueTasks) {
                params['lt_contactThread-contactThreadTasks-startAt'] = this.getCurrentDate()
                params['eq_contactThread-contactThreadTasks-status'] = 'O'
              }

              if (this.filters.withOrganization) {
                params['isNotNull_contactThread-contact-organization'] = ''
              }

              if (this.filters.tags && this.filters.tags.length) {
                const tags = this.filters.tags.map(tag => (typeof tag === 'string' ? tag : tag.id))
                if (this.filters.tagsSum) {
                  params['inD_contactThread-contact-contactTypes-id'] = JSON.stringify(tags)
                } else {
                  params['eqArr_contactThread-contact-contactTypes-id'] = JSON.stringify(tags)
                }
                // params['eqArr_contactThread-contact-contactTypes-id'] = JSON.stringify(this.filters.tags.map(tag => (typeof tag === 'string' ? tag : tag.id)))
              }

              if (this.filters.closeStatuses && this.filters.closeStatuses.length) {
                params.eqArr_status = JSON.stringify(this.filters.closeStatuses)
              }
            }

            axiosIns.get('1/contacts/threads/funnelProcesses', { params })
              .then(res => {
                this.loading = false
                funnelItems = (res.data.data.items || []).map(i => ({ ...i, ...i.contactThread }))
                this.pagination.totalRecords = res.data.data.totalItemCount
              })
              .finally(() => {
                this.tableData = funnelItems

                this.massUpdateContacts = []
                this.funnelPoints = funnelItems
                funnelItems.forEach(({ contact }) => {
                  if (!this.massUpdateContacts.find(u => u.id === contact.id)) {
                    this.massUpdateContacts.push(contact)
                  }
                })
              })
          })
      })
    },
    // Tester
    onChangeContactStatus(statusId, targetId, contact) {
      const updateData = {
        id: contact.id.toString(), status: statusId.toString(),
      }
      axiosIns.patch('1/contacts', updateData)
        .then(() => {
          this.getFunnelStructure()
        })
    },
    openModal(modalType = 'note', modalTitle = 'Nowa Notatka', okButtonTitle = 'Dodaj notatkę') {
      this.$root.$emit('bv::hide::popover')
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType,
          modalTitle,
          okButtonTitle,
        })
    },
    changeTarget(target, rowIndex) {
      this.selectedRowIndex = rowIndex
      this.target = target
    },
  },
}
</script>

<style lang="scss">
.search-input-group {
  box-shadow: unset !important;

.input-search, .input-group-text {
  background: unset;
  border: unset;
  border-bottom: 1px solid #d8d6de;
  border-radius: 0;

&:focus {
   background: unset;
   border-bottom: 1px solid var(--purple);
 }
}
}
.dropdown-menu.show {
  z-index: 99;
}
#table {
  .popover {
    min-width: 280px;
    max-width: 350px !important;
    .popover-body {
      padding: 0.65rem 0 !important;
    }
  }
}
</style>
